import React, { useContext, useState } from 'react'
import { useAuth } from '../../context/authContext.js';

// import styled from 'styled-components';
import { faMars, faVenus, faHeart, faLocationDot, faMarsAndVenus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LanguageContext } from "../../context/langContext";
import { Link, useNavigate } from 'react-router-dom';
import '../../styles/global.scss'
import { SaleInformationOneCatContext, SaleInformationOneCatProvider } from '../../context/SaleInformationOneCatContext.js';
import { CatContext, CatContextProvider } from '../../context/CatContext.js';

//some data come from context (saleInformation) and some data (saleCat) from the parent component (Market and Order)
// saleCat from Market has all data cat+saleInformation
const CatCardSell = ({ catId }) => {
    // console.log('CatCardSell | saleCat = ', saleCat)
    // console.log('CatCardSell | catId =', catId)
    return (
        <CatContextProvider catId={catId}>
            <SaleInformationOneCatProvider catId={catId}>
                <CatCardSellChild />
            </SaleInformationOneCatProvider>
        </CatContextProvider>
    );
}

const CatCardSellChild = () => {
    const catContext = useContext(CatContext);
    // console.log('CatCardSellChild | data: catContext = ', catContext) //standart info about cat ready to use. Output: { ems : "AWH" and etc}
    const catName = catContext?.data?.cat_name
    const catNamePrefix = catContext?.data?.cat_name_cattery_prefix
    const catNameSuffix = catContext?.data?.cat_name_suffix
    const age = catContext?.data?.age
    const birthdate = catContext?.data?.cat_birthdate
    const catId = catContext?.data?.id
    const catAvaUrl = catContext?.data?.cat_ava_url
    const sex = catContext?.data?.sex
    const breedRu = catContext?.data?.breed_ru
    const breedEn = catContext?.data?.breed_en
    const countryRu = catContext?.data?.country_ru
    const countryEn = catContext?.data?.country_en
    // const currency = catContext?.data?.currency_abb;

    // const { breed_en, breed_ru, index, currency_abb } = cat;
    // Check if the page is the market page
    const isMarketPage = window.location.pathname === '/market';
    const { data = {}, loading: saleLoading, error: saleError } = useContext(SaleInformationOneCatContext);
    // console.log('CatCardSell | data = ', data)
    const saleData = data?.data[0];
    // console.log('CatCardSell | saleData = data.data[0] = ', saleData)
    const catAva = '/images/catAva.jpg'
    const [isHovered, setIsHovered] = useState(false);
    const [isHoveredCard, setIsHoveredCard] = useState(false);
    const [isAddedToFavorite, setIsAddedToFavorite] = useState(false);
    const { translations, language } = useContext(LanguageContext)
    const { isAuthenticated } = useAuth(); //  <AuthContext.Provider value={{ currentUser, login, logout, isAuthenticated, userRoles }}>
    const navigate = useNavigate();
    // const location = useLocation();

    if (!saleData) { return <div>No data</div> }

    // Handle loading states
    if (saleLoading) return <div>Loading...</div>;

    // // Handle errors
    if (saleError) return <div>Error: {saleError?.message}</div>;

    const styles = {
        catCard: {
            display: 'flex',
            flexDirection: "column",
            // justifyContent: "space-between",
            // alignItems: 'stretch',
            width: '100%', /* Container with 100% takes full width of its parent */
            overflow: 'hidden',
            height: '100%',
            cursor: isHoveredCard ? 'pointer' : 'default',
            border: '1px solid lightgray',
            transition: 'box-shadow 0.15s ease-in-out',
            // margin: '0 auto',
            boxShadow: isHoveredCard ? '0 0 7px rgba(0, 0, 0, 0.5)' : '0 0 3px rgba(0, 0, 0, 0.2)'
        },
        catAvaRelative: {
            position: 'relative',
            // border: '2px solid red'
        },
        catCardImg: {
            width: '100%', /* Image takes full width of container */
            objectFit: 'cover',
            height: 'auto',
            // maxHeight: '280',
            // maxWidth: '320'
        },
        iconMarsWrap: {
            border: '1px solid rgba(0, 0, 0, 0)',
            backgroundColor: 'rgba(0, 0, 0, 0.2)',
            padding: '5px',
            position: 'absolute',
            top: '0',
            right: '0px',
        },
        iconHeartWrap: {
            // backgroundColor: 'rgba(0, 0, 0, 0.1)',
            padding: '5px',
            position: 'absolute',
            bottom: '3px',
            right: '0px',
        },
        idWrap: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            padding: '4px 1px 0 7px',
            position: 'absolute',
            top: '0',
            left: '0',
        },

        // Nested styles can be handled separately
        iconMars: {
            color: 'var(--icon-mars)',
            fontSize: '1.1em',
            width: '24px',
            height: '24px'
        },
        iconVenus: {
            color: 'var(--icon-venus)',
            fontSize: '1.1em',
            width: '24px',
            height: '24px'
        },
        id: {
            color: 'rgba(255, 255, 255, 1)',
            fontSize: '0.8rem',
            // width: '24px',
            // height: '24px'
        },
        iconHeart: {
            color: isAddedToFavorite ? 'rgb(255, 138, 225)' : (isHovered ? 'rgba(245, 138, 222, 0.8)' : 'rgba(255, 255, 255, 0.7)'),
            fontSize: '1.1em',
            // backgroundColor:'pink',
            width: '24px',
            height: '24px',
            cursor: isHovered ? 'pointer' : 'default',
            transition: 'color 0.15s ease-in-out'
        },
        desc: {
            display: 'flex',
            flexDirection: "column",
            justifyContent: "space-between",
            textAlign: 'center',
            padding: '0.8em 0.1em',
            // border: '2px solid red',
            height: '100%',
        },
        descUp: {
            // borderBottom: '1px solid lightgray',
            textAlign: 'center',
            paddingBottom: '0.3em',
            lineHeight: '1.2rem'
        },
        descTextSmall: {
            fontSize: 'small',
            // margin: '0.3em 0 0.3em 0',
            // padding: '0.1em 0 0.1em 0'
        },
        descBottom: {
            // display: 'flex',
            // alignItems: 'flex-end',
            // justifyContent: "center",
            borderTop: '1px solid lightgray',
            padding: '0.2em',
        },
        descBottomTextSmall: {
            fontSize: 'small',
            margin: '0.3em 0 0.3em 0',
            padding: '0.1em 0 0.1em 0'
        },
        descPriceWrap: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            flexDirection: 'row',
            backgroundColor: 'var(--main-color-back)',
            // backgroundColor: 'rgb(251, 233, 231)',
            padding: '0.2em',
            margin: '0 0.1em 0 0.1em'
        },
        descPrice: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            // fontSize: '1.1em',
            fontWeight: '600',
            padding: '0.2em 0.3em 0.2em 0.3em',
        },
        descPriceText: {
            fontSize: '0.6em',
            margin: '0 1px 0 5px',
            // textAlign: 'right'
            // paddingTop: '0.3em'
        },
        age: {
            fontSize: '0.9em',
            fontStyle: 'italic'
        },
        local: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            gap: '1rem',
            justifyContent: 'center',
        },
        iconMarsAndVenus: {
            margin: "0 7px 0 0"
        }
    };

    const OnClickFavorite = (e) => {
        e.stopPropagation();  // Prevents the Link from being triggered
        setIsAddedToFavorite(!isAddedToFavorite);
        // if (!isAddedToFavorite) {
        //     // console.log('Added to Favorite')
        //     setIsAddedToFavorite(true);
        // } else {
        //     // console.log('Delited to Favorite')
        //     setIsAddedToFavorite(false);
        // }
    };

    //it comes when retrieve data on Market page. There is calculation on backend and it is send within data
    const yearsTranslation = age?.years === 1
        ? translations['year']
        : age?.years > 4
            ? translations['let_years']
            : translations['years'];
    const monthsTranslation = age?.months === 1
        ? translations['month']
        : age?.months > 4
            ? translations['months5']
            : translations['months'];
    const daysTranslation = translations.days;

    const catBirthdate = birthdate ? birthdate : '~';
    const years = age?.years ? age?.years : '';
    const months = age?.months ? age?.months : '';
    const days = age?.days ? age?.days : '';
    const yearsText = age?.years ? `${years} ${yearsTranslation}` : '';
    const monthsText = age?.months ? `${months} ${monthsTranslation}` : '';
    let catAge;
    // console.log('Years:', age?.years);
    // console.log('Months:', age?.months);
    // console.log('Days:', age?.days);
    if (birthdate) {
        if (days > 0) {
            catAge = `(${yearsText} ${monthsText} ${days} ${daysTranslation})`;
        } else {
            catAge = `(${yearsText} ${monthsText})`;
        }
    }


    // console.log("catAge", catAge);

    const handleButtonClick = () => {
        if (isAuthenticated) {
            navigate(`/order/${catId}`);
            // navigate(`/order/${catId}`, { state: { saleCat: cat } }); //I don't use state to trinsfer data since I started using catContext
        } else {
            navigate(`/join`);
        }
    };

    return (
        <div
            key={catId}
            style={styles.catCard}
            onMouseEnter={() => setIsHoveredCard(true)}
            onMouseLeave={() => setIsHoveredCard(false)}
        >
            <div style={styles.catAvaRelative}>
                {catAvaUrl ? (
                    <Link className='nolink' to={`/cat-profile-sell/${catId}`}>
                        <img style={styles.catCardImg}
                            src={catAvaUrl ? catAvaUrl : catAva}
                            alt={catAvaUrl ? ('Photo of ' + catName) : ('catAva')} />
                    </Link>
                ) : (
                    <Link className='nolink' to={`/cat-profile-sell/${catId}`}>
                        <img style={styles.catCardImg} src={catAva} alt="catAva" />
                    </Link>
                )}

                <div style={styles.iconMarsWrap}>
                    {sex === "male" ? (
                        <FontAwesomeIcon icon={faMars} style={styles.iconMars} />
                    ) : (
                        <FontAwesomeIcon icon={faVenus} style={styles.iconVenus} />
                    )}
                </div>
                <div style={styles.idWrap}>
                    <div style={styles.id}>
                        ID {catId ? catId : ''}
                    </div>
                </div>
                <div
                    onMouseEnter={() => setIsHovered(true)}
                    onMouseLeave={() => setIsHovered(false)}
                    onClick={OnClickFavorite}
                    style={styles.iconHeartWrap}>
                    <FontAwesomeIcon icon={faHeart} style={styles.iconHeart} />
                </div>
            </div>

            <div style={styles.desc}>
                <Link className='nolink' to={`/cat-profile-sell/${catId}`}>
                    <div style={styles.descUp}>
                        <strong>
                            {catNamePrefix ? catNamePrefix : ''} {catName ? catName : ''} {catNameSuffix ? catNameSuffix : ''}
                        </strong>
                        <p style={styles.descTextSmall}>
                            {translations.breed}: {language === 'ru' ? breedRu : breedEn ? breedEn : ''}
                        </p>
                        <div style={styles.descTextSmall}>
                            {catBirthdate}
                            <p style={styles.age}>{catAge}</p>
                            {/* {cat_birthdate ? cat_birthdate : '~12 may 2015 '} ({age?.years ? age?.years : '~3'} {translations.years} {age?.months ? age?.months : '~8'} {translations.months}) */}
                        </div>
                    </div>
                </Link>
                <div style={styles.descBottom}>
                    <div style={styles.local}>
                        <FontAwesomeIcon icon={faLocationDot} />
                        <p style={styles.descBottomTextSmall}>{language === 'ru' ? countryRu : countryEn}</p>
                    </div>
                    <div style={styles.descPriceWrap}>
                        <div style={styles.descPrice}>{saleData?.price_pet ? saleData?.price_pet : "~"}
                            <div style={styles.descPriceText}>{saleData.currency_abb ? saleData.currency_abb : '~'}</div>
                        </div>
                        <div
                            style={styles.descPrice}>
                            <FontAwesomeIcon style={styles.iconMarsAndVenus} icon={faMarsAndVenus} />
                            {saleData?.price_breeding ? saleData?.price_breeding : "~"}
                            <div style={styles.descPriceText}>{saleData?.currency_abb ? saleData?.currency_abb : '~'}</div>
                        </div>
                    </div>
                    {isMarketPage && (
                        <button className='secondary-button-small' onClick={handleButtonClick}>
                            {translations.market.book}
                        </button>
                    )}
                </div>
            </div>
        </div >
    );
}

export default CatCardSell

// Optimized Code (Accessing Properties Directly):

// const catContext = useContext(CatContext);

// // Access only what you need, when you need it
// const catName = catContext?.data?.cat_name;
// const catBreed = catContext?.data?.breed_en;
//  =======
// Why This Is More Efficient:
// Memory Usage: You avoid creating a new variable (cat) that holds the entire object. This is particularly useful if the object is large, or if you only need one or two properties from it.
// Code Readability: It's clear which parts of the context are actually being used, which can make the code easier to read and maintain.
// Avoiding Unnecessary Operations: If you only need a single property, directly accessing it avoids the unnecessary step of assigning the entire object to a variable and then extracting properties from it.
